<template>
  <article
    v-if="!loading"
    class="user box"
  >
    <VCard class="user__body">
      <VAvatar
        class="user__avatar"
        :size="$vuetify.breakpoint.smAndDown ? 80 : 256"
        rounded
      >
        <img
          v-if="user.photo"
          :src="`/${user.photo}`"
          alt="Фото"
        >
        <VIcon v-else>
          person
        </VIcon>
      </VAvatar>
      <div class="actions">
        <VBtn
          v-if="user.canChangeHimself"
          :to="{ name: 'user-edit' }"
          depressed
          :large="$vuetify.breakpoint.lgAndUp"
          block
        >
          <VIcon left>
            edit
          </VIcon>{{ $t('edit.one') }}
        </VBtn>
      </div>
      <h1 class="user__title">
        {{ user.title }}
      </h1>

      <div
        v-if="isListener"
        class="user__content"
      >
        <UserInfo>
          <div
            v-if="user.parentTitle"
            class="user__option"
          >
            <label>{{ $t('contractors.one') }}: </label>
            {{ user.parentTitle }}
          </div>
          <div class="user__option">
            <label>{{ $t('usernames.one') }}: </label>
            {{ user.username }}
          </div>
          <div class="user__option">
            <label>{{ $t('passwords.one') }}: </label>
            {{ user.password }}
          </div>
        </UserInfo>

        <VDivider />

        <UserInfo
          title="Основная информация"
          :readonly="!user.canChangeHimself"
          anchor="base-information"
        >
          <div class="user__option">
            <label>{{ $t('full_name_person.one') }}: </label>
            <VTooltip
              bottom
              color="#fff"
            >
              <template #activator="{ on, value }">
                <button
                  type="button"
                  v-on="on"
                >
                  {{ user.title }}
                  <VIcon>{{ value ? 'arrow_drop_up': 'arrow_drop_down' }}</VIcon>
                </button>
              </template>

              <VCard>
                <VList dense>
                  <VListItem>
                    <VListItemContent>
                      <VListItemSubtitle>
                        {{ $t('full_name_person.one') }} <em>{{ $t('in_genitive_case.one') }}</em>:
                      </VListItemSubtitle>
                      <VListItemTitle>
                        {{ user.titleGenitive || $t('not_specified.one') }}
                      </VListItemTitle>
                    </VListItemContent>
                  </VListItem>
                  <VListItem>
                    <VListItemContent>
                      <VListItemSubtitle>
                        {{ $t('full_name_person.one') }} <em>{{ $t('in_dative_case.one') }}</em>:
                      </VListItemSubtitle>
                      <VListItemTitle>
                        {{ user.titleDative || $t('not_specified.one') }}
                      </VListItemTitle>
                    </VListItemContent>
                  </VListItem>
                  <VListItem>
                    <VListItemContent>
                      <VListItemSubtitle>
                        {{ $t('full_name_person.one') }} <em>{{ $t('in_accusative_case.one') }}</em>:
                      </VListItemSubtitle>
                      <VListItemTitle>
                        {{ user.titleAccusative || $t('not_specified.one') }}
                      </VListItemTitle>
                    </VListItemContent>
                  </VListItem>
                  <VListItem>
                    <VListItemContent>
                      <VListItemSubtitle>
                        {{ $t('full_name_person.one') }} <em>латиницей</em>:
                      </VListItemSubtitle>
                      <VListItemTitle>
                        {{ user.titleRomanized || $t('not_specified.one') }}
                      </VListItemTitle>
                    </VListItemContent>
                  </VListItem>
                </VList>
              </VCard>
            </VTooltip>
          </div>
          <div
            v-if="user.gender"
            class="user__option"
          >
            <label>{{ $t('gender.one') }}: </label>
            {{ user.gender }}
          </div>
          <div
            v-if="user.citizenship"
            class="user__option"
          >
            <label>{{ $t('citizenship.one') }}:</label>
            {{ getCountry(user.citizenship).title }}
          </div>
          <div
            v-if="user.dob"
            class="user__option"
          >
            <label>{{ $t('dob.one') }}: </label>
            {{ user.dob }}
          </div>
          <div
            v-if="user.inn"
            class="user__option"
          >
            <label>{{ $t('inn.one') }}: </label>
            {{ user.inn }}
          </div>
          <div
            v-if="user.passport"
            class="user__option"
          >
            <label>{{ $t('user_profile_passport_info.one') }}: </label>
            {{ user.passport }}
          </div>
          <div
            v-if="user.snils"
            class="user__option"
          >
            <label>{{ $t('snils.one') }}: </label>
            {{ user.snils }}
          </div>
        </UserInfo>

        <VDivider />

        <UserInfo
          title="Контактная информация"
          :readonly="!user.canChangeHimself"
          anchor="contacts-information"
        >
          <div class="user__option">
            <label>{{ $t('phones.one') }}:</label>
            {{ user.phone || $t('not_specified.one') }}
          </div>
          <div
            v-if="user.email"
            class="user__option"
          >
            <label>{{ $t('emails.one') }}:</label>
            <a
              :href="`mailto:${user.email}`"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ user.email }}
            </a>
          </div>
          <div
            v-if="user.locality"
            class="user__option"
          >
            <label>{{ $t('locality_of_a_person.one') }}:</label>
            {{ user.locality }}
          </div>
          <div
            v-if="user.address"
            class="user__option"
          >
            <label>{{ $t('address_of_a_person.one') }}:</label>
            {{ user.address }}
          </div>
          <div
            v-if="user.physicalAddress"
            class="user__option"
          >
            <label>{{ $t('physical_address_of_a_person.one') }}:</label>
            {{ user.physicalAddress }}
          </div>
          <div
            v-if="user.postalAddress"
            class="user__option"
          >
            <label>{{ $t('postal_address_of_a_person.one') }}:</label>
            {{ user.postalAddress }}
          </div>
        </UserInfo>

        <VDivider />

        <UserInfo
          title="Образование"
          :readonly="!user.canChangeHimself"
          anchor="education"
        >
          <div class="user__option">
            <label>{{ $t('information_education.one') }}: </label>
            {{ user.education || $t('not_specified.one') }}
          </div>
          <VCard
            v-if="user.certificates.length"
            class="mb-4"
            color="#f5f5f5"
          >
            <VCardTitle>{{ $t('information_doc_education_of_the_user.one') }}:</VCardTitle>
            <VCardText>
              <VCard
                v-for="(certificate, idx) in user.certificates"
                :key="idx"
                :class="{ 'mb-4': idx !== user.certificates.length }"
              >
                <VCardText>
                  <VRow>
                    <VCol
                      cols="12"
                      md="6"
                    >
                      <VListItem dense>
                        <VListItemContent>
                          <VListItemSubtitle>{{ $t('education_level.one') }}</VListItemSubtitle>
                          <VListItemTitle>{{ certificate.level || $t('not_specified.one') }}</VListItemTitle>
                        </VListItemContent>
                      </VListItem>
                    </VCol>
                    <VCol
                      cols="12"
                      md="6"
                    >
                      <VListItem dense>
                        <VListItemContent>
                          <VListItemSubtitle>{{ $t('certificate_full_name.one') }}</VListItemSubtitle>
                          <VListItemTitle>{{ certificate.fullname || $t('not_specified.one') }}</VListItemTitle>
                        </VListItemContent>
                      </VListItem>
                    </VCol>
                    <VCol
                      cols="12"
                      md="4"
                    >
                      <VListItem dense>
                        <VListItemContent>
                          <VListItemSubtitle>{{ $t('series.one') }}</VListItemSubtitle>
                          <VListItemTitle>{{ certificate.series || $t('not_specified.one') }}</VListItemTitle>
                        </VListItemContent>
                      </VListItem>
                    </VCol>
                    <VCol
                      cols="12"
                      md="4"
                    >
                      <VListItem dense>
                        <VListItemContent>
                          <VListItemSubtitle>{{ $t('numbers.one') }}</VListItemSubtitle>
                          <VListItemTitle>{{ certificate.number || $t('not_specified.one') }}</VListItemTitle>
                        </VListItemContent>
                      </VListItem>
                    </VCol>
                    <VCol
                      cols="12"
                      md="4"
                    >
                      <VListItem dense>
                        <VListItemContent>
                          <VListItemSubtitle>{{ $t('signed_at.one') }}</VListItemSubtitle>
                          <VListItemTitle>{{ certificate.signed_at || $t('not_specified.one') }}</VListItemTitle>
                        </VListItemContent>
                      </VListItem>
                    </VCol>
                    <VCol
                      cols="12"
                      md="6"
                    >
                      <VListItem dense>
                        <VListItemContent>
                          <VListItemSubtitle>{{ $t('educational_institution.one') }}</VListItemSubtitle>
                          <VListItemTitle>{{ certificate.edu || $t('not_specified.one') }}</VListItemTitle>
                        </VListItemContent>
                      </VListItem>
                    </VCol>
                    <VCol
                      cols="12"
                      md="6"
                    >
                      <VListItem dense>
                        <VListItemContent>
                          <VListItemSubtitle>{{ $t('educational_institution_level.one') }}</VListItemSubtitle>
                          <VListItemTitle>{{ certificate.edu_level || $t('not_specified.one') }}</VListItemTitle>
                        </VListItemContent>
                      </VListItem>
                    </VCol>
                  </VRow>
                </VCardText>
              </VCard>
            </VCardText>
          </VCard>
        </UserInfo>

        <VDivider />

        <UserInfo
          title="Работа"
          :readonly="!user.canChangeHimself"
          anchor="work"
        >
          <div class="user__option">
            <label>{{ $t('employers.one') }}: </label>
            {{ user.employer || $t('not_specified.one') }}
          </div>
          <div
            v-if="user.position"
            class="user__option"
          >
            <label>{{ $t('user_profile_position.one') }}: </label>
            {{ user.position }}
          </div>
        </UserInfo>
      </div>

      <div
        v-else
        class="user__content"
      >
        <UserInfo>
          <div
            v-if="user.email"
            class="user__option"
          >
            <label>{{ $t('emails.one') }}: </label>
            <a
              :href="`mailto:${user.email}`"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ user.email }}
            </a>
          </div>

          <div class="user__option">
            <label>{{ $t('information_education.one') }}: </label>
            {{ user.education || $t('not_specified.one') }}
          </div>

          <div class="user__option">
            <label>{{ $t('user_profile_position.one') }}: </label>
            {{ user.position || $t('not_specified.one') }}
          </div>
        </UserInfo>
      </div>
    </VCard>
  </article>

  <VProgressCircular
    v-else
    :size="50"
    color="primary"
    indeterminate
    class="app-loader"
  />
</template>

<script>
import { getUser } from '@/api/api'
import documentTitle from '../mixins/documentTitle'
import { getCountry } from '../utils/countries'
import UserInfo from '@components/UserInfo.vue'
import { isListener } from '../utils/role'

class User {
  #gender = ''

  constructor (account = {}) {
    this.id = account.id || 0
    this.parentTitle = account.parent_title || ''
    this.username = account.username || ''
    this.password = account.password || ''
    this.title = account.title || ''
    this.titleGenitive = account.title_genitive || ''
    this.titleDative = account.title_dative || ''
    this.titleAccusative = account.title_accusative || ''
    this.titleRomanized = account.title_romanized || ''
    this.photo = account.photo || account.profile?.photo || ''
    this.email = account.email || ''
    this.phone = account.phone || ''
    this.position = account.position || ''
    this.employer = account.profile?.employer || ''
    this.canChangeHimself = account.profile?.can_change_himself || false
    this.citizenship = account.profile?.citizenship || ''
    this.dob = account.profile?.dob || ''
    this.inn = account.profile?.inn || ''
    this.passport = account.profile?.passport || ''
    this.snils = account.profile?.snils || ''
    this.locality = account.profile?.locality || ''
    this.address = account.profile?.address || ''
    this.physicalAddress = account.profile?.physical_address || ''
    this.postalAddress = account.profile?.postal_address || ''
    this.education = account.education || account.profile?.education
    this.certificates = account.profile?.certificates || []
    this.role = account.role?.code || ''

    this.#gender = account.profile?.gender || ''
  }

  get gender () {
    if (this.#gender === 'f') {
      return 'Женский'
    } else if (this.#gender === 'm') {
      return 'Мужской'
    }

    return this.#gender
  }
}

export default {
  name: 'TheUser',

  components: {
    UserInfo
  },

  mixins: [documentTitle],

  data () {
    return {
      loading: false,
      user: new User()
    }
  },

  computed: {
    isListener () {
      return isListener(this.user.role)
    }
  },

  watch: {
    $route: {
      handler: async function (to) {
        try {
          this.loading = true
          const { user } = await getUser(to.params.userID)
          this.user = new User(user)

          this.documentTitle = user.title
          this.loading = false
        } catch {
          this.$router.replace({ name: 'sessions' }).catch(() => {})
        }
      },
      immediate: true
    }
  },

  methods: {
    getCountry
  }
}
</script>

<style lang="scss">
.user {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: 1rem;
  }
}

.user__title {
  grid-area: title;
  font-size: 1.875rem;
  line-height: 2.5rem;
}

.user__avatar {
  grid-area: avatar;
  background-color: #ccc;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: sticky;
    top: calc(var(--app-bar-height) + 1rem);
  }

  .v-icon {
    font-size: 40px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 160px;
    }
  }
}

.user__option {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: $spacer * 2;
  margin-bottom: $spacer * 2;

  &:last-of-type {
    margin-bottom: $spacer * 4;
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex-direction: row;
  }

  label {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      flex: 0 0 16rem;
    }
  }
}

.user__content {
  grid-area: content;
  max-width: 60rem;
}

.user__body {
  padding: $spacer * 4;
  display: grid;
  gap: ($spacer * 3) ($spacer * 6);
  grid-template-areas:
    "avatar title"
    "actions actions"
    "content content";
  grid-template-columns: 80px 1fr;
  grid-auto-rows: min-content;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 256px 1fr;
    grid-template-areas:
      "avatar title"
      "avatar content"
      "actions content"
      ". content";
  }

  > .actions {
    grid-area: actions;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      position: sticky;
      top: calc(var(--app-bar-height) + 256px + 1.5rem);
    }
  }
}
</style>
